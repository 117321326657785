import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
import '../../styles/global.scss';
import "swiper/css";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import React from "react";

interface Slide {
  image: string;
  caption: string;
  time?: string;
}

interface SwiperProps {
  slides: Slide[];
  name: string;
  pagination?: boolean;
}

const SwiperJs: React.FC<SwiperProps> = ({ slides, pagination, name }) => {
  return (
    <div className="container">
      <Swiper
        centeredSlides={true}
        pagination={{ clickable: pagination ?? true }}
        slidesPerView={1}
        spaceBetween={50}
        initialSlide={1.5}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        breakpoints={{
          770: {
            slidesPerView: 1.5,
          },
        }}
        className={name}
        id="swipers"
      >
        {slides.map((slide: any) => (
          <SwiperSlide key={slide.key}>
            <img src={slide.image} alt={`Gallery`} />
            {/* <p className="cgi">Computer generated image</p> */}
            <div className="caption">{slide.caption}</div>
            <div
              className="time"
              style={{ display: slide.time === undefined ? "none" : "flex" }}
            >
              <img src="../assets/images/swiper/swiperarrs.svg" style={{width: '100%'}} alt="slide" />
              <h2>{slide.time}</h2>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperJs;
