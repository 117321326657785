import { motion } from "framer-motion";
import "../styles/footer.scss";
import React from "react";

const Footer: React.FC = () => {
  return (
    <>
      <div className="footer-wrapper">
        <div className="l-container">
          <div></div>
          <img src="../assets/images/logos/logo.svg" alt="logo" />
          <div className="f-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="205"
              height="51"
              viewBox="0 0 205 51"
            >
              <text
                id="A_development_by"
                data-name="A development by"
                fill="#2b302c"
                fontSize="20"
                fontFamily="Uniform-Condensed6, 'Uniform \u.... '"
                letterSpacing="-0.014em"
              >
                <tspan x="30.994" y="16">
                  A development by
                </tspan>
                <tspan fontFamily="Uniform-Condensed6">
                  <tspan x="102.5" y="40"></tspan>
                </tspan>
              </text>
            </svg>
            <img
              src="../assets/images/logos/cli.svg"
              alt="Cli Dartriver Logo"
            />
          </div>
        </div>
        <div className="r-container">
          <div className="r-wrapper">
            <h2 style={{ paddingBottom: "25px" }}>JOIN US, GET IN TOUCH</h2>
            <div className="wrapper">
              <div>
                <img
                  className="agents"
                  src="../assets/images/logos/usp.svg"
                  alt="Usp Logo"
                />
                <div className="agents">
                  <h2>RUPERT COWLING</h2>
                  <p>07736 880317</p>
                  <a href="mailto:rupert@usp.london">
                    <p>rupert@usp.london</p>
                  </a>
                </div>
                <div className="agents">
                  <h2>LUKE AUSTERBERRY</h2>
                  <p>07921 406291</p>
                  <a href="mailto:luke@usp.london">
                    <p>luke@usp.london</p>
                  </a>
                </div>
              </div>
              <div className=" hdr">
                <img
                  className="agents"
                  src="../assets/images/logos/rx.svg"
                  alt="Rx Logo"
                />
                <div className="left">
                  <div className="agents">
                    <h2>JORDAN ADAIR</h2>
                    <p>07880 552 710</p>
                    <a href="mailto:Jordan.adair@rx.london">
                      <p>Jordan.adair@rx.london</p>
                    </a>
                  </div>
                  <div className="agents">
                    <h2>HANNAH BUXTON</h2>
                    <p>07976 747 569</p>
                    <a href="mailto:hannah.buxton@rx.london">
                      <p>hannah.buxton@rx.london</p>
                    </a>
                  </div>
                </div>
                <div className="right">
                  <div className="agents">
                    <h2>JACK MOATES</h2>
                    <p>07494 232 394</p>
                    <a href="mailto:jack.moates@rx.london">
                      <p>jack.moates@rx.london</p>
                    </a>
                  </div>
                  <div className="agents">
                    <h2>ALFIE JOHN</h2>
                    <p>07377 885 814</p>
                    <a href="mailto:alfie.john@rx.london">
                      <p>alfie.john@rx.london</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="download">
                <h2 className="desktop">DOWNLOADS</h2>
                <a href="../downloads/brochure.pdf" target="_blank">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                  style={{ cursor: "pointer" }}
                >
                  <h2>BROCHURE</h2>
                </motion.button>
                </a>
                <h2 style={{ color: "#C29134" }}>
                  UNION HOUSE, 182-194 UNION STREET, SE1 0LH
                </h2>
              </div>
            </div>
            <div className="misrep desktop">
              <p>
                Misrepresentation Act 1967. RX London and their clients give
                notice that: RX London for themselves and the vendor of this
                property give notice that these particulars do not form, or form
                part of, any offer or contract. They are intended to give fair
                description of the property and whilst every effort has been
                made to ensure their accuracy this cannot be guaranteed. Any
                intending purchaser must therefore satisfy themselves by
                inspection or otherwise. RX London nor any of its employees has
                any authority to make or give any further
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
