import ReactDOM from "react-dom/client";
import Footer from "./components/footer";
import HomePage from "./pages/homepage";
import Building from "./pages/building";
import Header from "./components/header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Burger from "./components/burger/burger/burger";
import Contact from "./pages/contact";
import Availability from "./pages/availability";
import Location from "./pages/location";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-X3THBND7CP'
}

TagManager.initialize(tagManagerArgs)


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Burger />
    <Header />
    <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/building" element={<Building/>} />
        <Route path="/availability" element={<Availability />} />
        <Route path="/location" element={<Location />} />
        <Route path="/contact" element={<Contact />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
