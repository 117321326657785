import { useState } from "react";
import "../styles/location.scss";
import { motion } from "framer-motion";
import SwiperJs from "../components/swiper/swiper";
import AnimatedImage from "../components/animated_image";

const Location: React.FC = () => {
  const [isActive, setIsActive] = useState(false);

  const basePath: string = "../assets/images/location/";
  const artsList: string[] = [
    "Hayward Gallery",
    "Southbank Centre",
    "Royal Festival Hall",
    "National Theatre",
    "London Eye",
    "Young Vic Theatre",
    "The Old Vic",
    "Union Theatre",
    "Jerwood Space",
    "Old Union Yard Arches",
  ];

  const foodList = [
    "Skylon",
    "Anchor & Hope",
    "Marco’s by Marco Pierre White",
    "Bread Street Kitchen & Bar",
    "Seabird",
    "Gillray’s Steakhouse & Bar",
    "Flat Iron Square",
    "Oxo Tower Restaurant",
    "Roast",
    "Padella",
    "Tapas Brindisa",
    "El Pastor",
    "The Gentlemen Barista",
    "Mc & Sons",
  ];

  const slides = [
    {
      image: `${basePath}/mb_timeline/1.jpg`,
      caption: "BREAD STREET KITCHEN",
      time: "1 MIN WALK",
    },
    {
      image: `${basePath}/mb_timeline/2.jpg`,
      caption: "OLD UNION YARD ARCHES",
      time: "2 MIN WALK",
    },
    {
      image: `${basePath}/mb_timeline/3.jpg`,
      caption: "FLAT IRON SQUARE",
      time: "5 MIN WALK",
    },
    {
      image: `${basePath}/mb_timeline/4.jpg`,
      caption: "TATE MODERN",
      time: "6 MIN WALK",
    },
    {
      image: `${basePath}/mb_timeline/5.jpg`,
      caption: "TAPAS BRINDISA",
      time: "10 MIN WALK",
    },
    {
      image: `${basePath}/mb_timeline/6.jpg`,
      caption: "BOROUGH MARKET",
      time: "10 MIN WALK",
    },
    {
      image: `${basePath}/mb_timeline/7.jpg`,
      caption: "BOROUGH YARDS",
      time: "10 MIN WALK",
    },
    {
      image: `${basePath}/mb_timeline/8.jpg`,
      caption: "SOUTHBANK CENTER",
      time: "12 MIN WALK",
    },
  ];
  return (
    <>
      <div className="map-wrapper">
        <div className="mobile map-header">
          <h2>BE PART OF THE BUSTLING SOUTH</h2>
        </div>

        <img
          src={`${basePath}ldnMap.png`}
          alt="test"
          style={{ width: "100%" }}
        />
      </div>
      <div className="timeline-wrapper">
        <div className="timeline-section">
          <div className="mobile buttons ">
            <button onClick={() => setIsActive(true)} className="south-btns">
              <motion.p
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
              >
                Arts & Culture &#9660;
              </motion.p>
            </button>
            <button onClick={() => setIsActive(false)} className="south-btns">
              <motion.p
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
              >
                Food & Drink &#9660;
              </motion.p>
            </button>
          </div>
          <div className={`l pins ${!isActive ? "inactive" : ""}`}>
            <div>
              <svg
                id="Group_3"
                data-name="Group 3"
                xmlns="http://www.w3.org/2000/svg"
                width="162"
                height="184"
                viewBox="0 0 162 184"
              >
                <text
                  id="ARTS_CULTURE"
                  data-name="ARTS &amp; CULTURE"
                  transform="translate(0 67)"
                  fill="#c29134"
                  font-size="40"
                  // eslint-disable-next-line
                  font-family="Uniform-Condensed2, 'Uniform \32 '"
                >
                  <tspan x="0" y="32">
                    ARTS &amp;{" "}
                  </tspan>
                  <tspan x="0" y="87">
                    CULTURE
                  </tspan>
                </text>
                <circle id="Oval" cx="24" cy="24" r="24" fill="#c29134" />
              </svg>
            </div>
            <div>
              {artsList.map((item: string, index: number) => (
                <p className="pin">
                  {index + 1} {item}
                </p>
              ))}
            </div>
          </div>
          <div className={`r pins ${isActive ? "inactive" : ""}`}>
            <div>
              <svg
                id="Group_2"
                data-name="Group 2"
                xmlns="http://www.w3.org/2000/svg"
                width="162"
                height="184"
                viewBox="0 0 162 184"
              >
                <text
                  id="FOOD_DRINK"
                  data-name="FOOD &amp; DRINK"
                  transform="translate(0 67)"
                  fill="#697753"
                  font-size="40"
                  // eslint-disable-next-line
                  font-family="Uniform-Condensed2, 'Uniform \32 '"
                >
                  <tspan x="0" y="32">
                    FOOD{" "}
                  </tspan>
                  <tspan x="0" y="87">
                    &amp; DRINK
                  </tspan>
                </text>
                <circle
                  id="Oval_Copy"
                  data-name="Oval Copy"
                  cx="24"
                  cy="24"
                  r="24"
                  fill="#697753"
                />
              </svg>
            </div>
            <div>
              {foodList.map((item: string, index: number) => (
                <p className="pin">
                  {index + 1} {item}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="timeline-section">
          <div className="l logo">
            <img src="../assets/images/logos/logo.svg" alt="logo" />
          </div>
          <div className="r logo"></div>
        </div>
        <div className="timeline-section">
          <div className="l edp">
            <img
              src="../assets/images/icons/yellowarrow.svg"
              className="timelineadd arr"
              alt="icon"
            />

            <img
              src="../assets/images/icons/green-dott.svg"
              className="timelineadd"
              alt="greenarrow"
            />
            <p className="text">
              Union House sits at the heart of London’s Southbank, surrounded by
              the citys best bars, restaurants, markets and cultural
              institutions.
            </p>
            <div className="tag">
              <div className="tag-wrapper">
                <p className="tag-text">1 MIN WALK</p>
              </div>
            </div>
            <div className="img-wrapper">
              <AnimatedImage
                src={`${basePath}timeline/1.jpg`}
                alt={"2"}
                direction={"-400"}
                padding={"50px 0px"}
              />
            </div>
            <div className="tag">
              <div className="tag-wrapper">
                <p className="tag-text">5 MIN WALK</p>
              </div>
            </div>
            <div className="img-wrapper">
              <AnimatedImage
                src={`${basePath}timeline/4.jpg`}
                alt={"2"}
                direction={"-400"}
                padding={"50px 0px 250px 0px"}
              />
            </div>
            <div className="tag">
              <div className="tag-wrapper">
                <p className="tag-text">10 MIN WALK</p>
              </div>
            </div>
            <div className="img-wrapper">
              <AnimatedImage
                src={`${basePath}timeline/6.jpg`}
                alt={"2"}
                direction={"-400"}
                padding={"50px 0px"}
              />
            </div>
            <h2 style={{ paddingTop: "50px" }}>
              AN ABUNDANCE <br></br>
              OF EXPERIENCES <br></br>
              WITHIN WALKING <br></br>
              DISTANCE
            </h2>
          </div>

          <div className="r edp">
            <h2>
              EAT, DRINK <br></br>
              AND PLAY
            </h2>

            <AnimatedImage
              src={`${basePath}timeline/2.jpg`}
              alt={"2"}
              direction={"400"}
              padding={"50px 50px"}
            />
            <div className="tag">
              <div className="tag-wrapper">
                <p className="tag-text">2 MIN WALK</p>
              </div>
            </div>
            <div className="tag" style={{ paddingTop: "500px" }}>
              <div className="tag-wrapper">
                <p className="tag-text">6 MIN WALK</p>
              </div>
            </div>
            <AnimatedImage
              src={`${basePath}timeline/3.jpg`}
              alt={"2"}
              direction={"400"}
              padding={"50px 50px"}
            />
            <AnimatedImage
              src={`${basePath}timeline/5.jpg`}
              alt={"2"}
              direction={"400"}
              padding={"50px 50px"}
            />

            <div className="tag" style={{ paddingTop: "50px" }}>
              <div className="tag-wrapper">
                <p className="tag-text">12 MIN WALK</p>
              </div>
            </div>

            <AnimatedImage
              src={`${basePath}timeline/8.jpg`}
              alt={"2"}
              direction={"400"}
              padding={"50px 50px"}
            />
          </div>
        </div>

        <div className="timeline-section">
          <h2>EAT, DRINK PLAY</h2>
          <p>
            Union House sits at the heart of London’s Southbank, surrounded by
            the citys best bars, restaurants, markets and cultural institutions.
          </p>

          <SwiperJs slides={slides} name={"loc-swiper"} pagination={false} />
        </div>
      </div>
      <div className="illust">
        <div
          className="ill-title"
          style={{
            paddingTop: "75px",
            paddingBottom: "50px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <h2>
            A HOP, SKIP AND A JUMP <br /> FROM SOUTHWARK STATION
          </h2>
        </div>
        <img src={`${basePath}transportmap.png`} alt="spider" loading="lazy" />
      </div>
    </>
  );
};

export default Location;
