import { motion } from "framer-motion";
import { useState } from "react";

interface ImageProps {
  src: string;
  alt: string;
  direction: string;
  repeat?: boolean;
  padding?: string;
}

const AnimatedImage: React.FC<ImageProps> = ({
  src,
  alt,
  direction,
  repeat,
  padding,
}) => {

  
  const [hasEntered, setHasEntered] = useState(false);

  return (
    <>
      <motion.img
        initial={{ x: direction, opacity: 0 }}
        animate={hasEntered ? { x: 0, opacity: 1 } : {}}
        onViewportEnter={() => setHasEntered(repeat ?? true)}
        viewport={{ once: true, amount: 0.8 }}
        transition={{ type: "spring", duration: 0.5, delay: 0.2 }}
        src={src}
        alt={alt}
        style={{ padding: padding }}
      />
    </>
  );
};

export default AnimatedImage;
