import "../styles/contact.scss";
import GoogleMapReact from "google-map-react";
const AnyReactComponent = ({ text }) => (
  <img src={text} style={{ width: "100px" }} alt="marker"/>
);
const mapProps = {
  center: {
    lat: 51.50407139726405,
    lng: -0.101256552656356,
  },
  zoom: 15,
};

const mapStyle = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      { saturation: 36 },
      { color: "#333333" },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      { visibility: "on" },
      { color: "#ffffff" },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      { color: "#fefefe" },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      { color: "#fefefe" },
      { lightness: 17 },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      { color: "#edebe4" },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry.fill",
    stylers: [{ color: "#e5e3d6" }],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry.fill",
    stylers: [{ color: "#697753" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      { color: "#f5f5f5" },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      { color: "#dedede" },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d1ecc7",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#bddddd",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#adc1ca",
      },
    ],
  },
];

const Contact = () => {
  return (
    <>
      <div className="cntwrapper">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBs9fOEZFVmtgCQT32tig-RtnryKmiWyjk" }}
          defaultCenter={mapProps.center}
          defaultZoom={mapProps.zoom}
          options={{
            styles: mapStyle,
          }}
        >
          <AnyReactComponent
            lat={51.503878971652355}
            lng={-0.10120723534237704}
            text={"../assets/images/contact/Map_marker.png"}
          />
        </GoogleMapReact>
      </div>
    </>
  );
};

export default Contact;
