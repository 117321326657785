import { motion } from "framer-motion";
import "../styles/header.scss";
import { Link } from "react-router-dom";
import React from "react";

const Header: React.FC = () => {
  return (
    <div className="header-wrapper">
      <Link to="/">
        <motion.img
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
          src="../assets/images/unionhouse.svg"
          alt="Union House"
        />
      </Link>
      <div className="header">
        <h2>LONDON SE1</h2>
      </div>
    </div>
  );
};

export default Header;
