import { ReactNode, useState } from "react";
import "./styles.css";
import { Variants, motion } from "framer-motion";
import { Link } from "react-router-dom";

const menuLinks = ["HOME", "BUILDING", "AVAILABILITY", "LOCATION", "CONTACT"];

const Burger = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const itemVariants: Variants = {
    open: {
      display: "flex",
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1,
      },
    },
    closed: { display: "none", transition: { duration: 0.2 } },
  };

  const constVariants: Variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "100%" },
  };

  const noStaggerItemVariants: Variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  };

  const btnone = {
    open: {
      rotate: 45,
      translateY: 7,
    },
    closed: {
      rotate: 0,
      translateY: 0,
    },
  };
  const bttwo = {
    open: {
      opacity: 0,
    },
    closed: {
      opacity: 1,
    },
  };

  const btnthree = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    open: {
      rotate: -45,
      translateY: -7,
    },
  };

  return (
    <>
      <motion.div className="openbtn" onClick={() => setIsOpen(!isOpen)}>
        <motion.img
          animate={isOpen ? "open" : "closed"}
          variants={btnone}
          initial={false}
          src="../assets/images/Line.svg"
          alt="burger"
        />
        <motion.img
          animate={isOpen ? "open" : "closed"}
          variants={bttwo}
          initial={false}
          src="../assets/images/Line.svg"
          alt="burger"
        />
        <motion.img
          animate={isOpen ? "open" : "closed"}
          variants={btnthree}
          initial={false}
          src="../assets/images/Line.svg"
          alt="burger"
        />
      </motion.div>

      <motion.div
        initial={false}
        variants={itemVariants}
        animate={isOpen ? "open" : "closed"}
        className="menu"
      >
        <motion.div className="iconwrapper">
          <motion.div className="icons" animate={isOpen ? "opens" : "closed"}>
            <motion.img
              className="hide"
              variants={noStaggerItemVariants}
              src="../assets/images/unionhouse.svg"
            />
            <motion.img
              variants={noStaggerItemVariants}
              src="../assets/images/logos/logo.svg"
            />
            <motion.h2 variants={noStaggerItemVariants}>LONDON, SE1</motion.h2>
          </motion.div>
        </motion.div>

        <motion.div className="linkwrapper">
          <motion.img
            variants={noStaggerItemVariants}
            src="../assets/images/unionhouse.svg"
            className="mobile"
          />
          {menuLinks.map(
            (link): ReactNode => (
              <motion.h2
                variants={constVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                onClick={() => setIsOpen(!isOpen)}
              >
                <Link
                  to={link.toLowerCase() === "home" ? " " : link.toLowerCase()}
                >
                  {link}
                </Link>
              </motion.h2>
            )
          )}
          <motion.img
            variants={noStaggerItemVariants}
            src="../assets/images/logos/logo.svg"
            className="mobile"
          />
        </motion.div>
      </motion.div>
    </>
  );
};

export default Burger;
